import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetch_contact } from "@store/contact.slice";
import TypedText from "@components/TypedText";
import parse from "html-react-parser";

const Contact = () => {
  const { info, is_fetching_contact } = useSelector((state) => state.contact);
  const { language } = useSelector((state) => state.config);
  const dispatch = useDispatch();

  const localized_info = useMemo(() => {
    if (info && Object.hasOwn(info, 'info') && Object.hasOwn(info, 'info-en')) {
      return language === "en" ? info["info-en"] : info["info"]
    }
    return ""
  }, [language, info])

  useEffect(() => {
    if (info === "" && !is_fetching_contact) {
      fetch();
    }
  }, []);

  const fetch = async () => {
    await dispatch(fetch_contact());
  };

  return (
    <>
      {info !== "" && (
        <div
          className="generic_container"
          oncopy="return false"
          onpaste="return false"
          oncut="return false"
        >
          {parse(localized_info)}
        </div>
      )}

      {info === "" && (
        <div className="generic_container">
          <div className="loader_wrapper">
            <TypedText text={[language === "en" ? "Please wait": "Παρακαλώ περιμένετε ...", 1000, ""]} />
          </div>
        </div>
      )}
    </>
  );
};

export default Contact;
