import { createSlice } from '@reduxjs/toolkit'

export const configSlice = createSlice({
    name: 'config',
    initialState: {
        language: null,
    },
    reducers: {
        set_language: (state, action) => {
            state.language = action.payload
        },
    },
})

export const { set_language } = configSlice.actions

export default configSlice.reducer