import React, { Component } from "react";

function NotFound() {
  return (
    <div className="not-found">
      <h1>404</h1>
      <h3>Page not found</h3>
    </div>
  );
}

export default NotFound;
