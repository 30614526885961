import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Api from "@services/about.service";

export const fetch_about = createAsyncThunk(
    'about/fetch_about',
    async (_, thunkAPI) => {
        try {
            const response = await Api.get_about();
            return response.data[0].acf
        } catch {
            return ""
        }
    }
)
export const aboutSlice = createSlice({
    name: 'about',
    initialState: {
        bio: "",
        is_fetching_about: false
    },
    reducers: {
        setBio: (state, action) => {
            state.bio = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetch_about.fulfilled, (state, action) => {
                state.bio = action.payload
                state.is_fetching_about = false
            })
            .addCase(fetch_about.pending, (state) => {
                state.is_fetching_about = true
            })
    },
})

export const { setBio } = aboutSlice.actions

export default aboutSlice.reducer