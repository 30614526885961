import React from "react";

function Project() {
  return (
    <>
      <div>Here the Project component is going to be diplayed</div>
    </>
  );
}

export default Project;
