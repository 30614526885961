import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetch_about } from "@store/about.slice";
import TypedText from "@components/TypedText";

const About = () => {
  const { bio, is_fetching_about } = useSelector((state) => state.about);
  const [renderCounter, setRenderCounter] = useState(0)
  const { language } = useSelector((state) => state.config);
  const dispatch = useDispatch();

  const localized_bio = useMemo(() => {
    if (bio && Object.hasOwn(bio, 'bio') && Object.hasOwn(bio, 'bio-en')) {
      return language === "en" ? bio["bio-en"] : bio["bio"]
    }
    return ""
  }, [language, bio])  

  useEffect(() => {
    if (bio === "" && !is_fetching_about) {
      fetch();
    }
  }, []);

  const localized_component = useMemo(() => {
    if(renderCounter < 1 && localized_bio !== "") {
      setRenderCounter((ol_v) => ol_v + 1 )
      return (
        <TypedText text={[localized_bio]} speed={200} />
      )
    }else {
      return (
        <div>{localized_bio}</div>
      )
    }
  }, [localized_bio])

  const fetch = async () => {
    await dispatch(fetch_about());
  };

  return (
    <div className="generic_container">
      {bio !== "" && (localized_component)}
      {bio === "" && (
        <div className="loader_wrapper">
          <TypedText text={[language === "en" ? "Please wait": "Παρακαλώ περιμένετε ...", 1000, ""]} />
        </div>
      )}
    </div>
  );
};

export default About;
