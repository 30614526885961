import Client from "./client.service";
import { home_images } from "@config/config"

class HomeImages extends Client {
    static path = home_images

    static url = (path, id = "") => {
        return this.api_url + this.api_url_prefix + path + id
    }

    static get_home_images = async (page = 1, pagesize = 100, params = {}) => {
        const url = `${this.url(this.path.LIST)}?${this._query_string({ page, pagesize, ...params })}`
        return this._fetch(url)
    }
}

export default HomeImages