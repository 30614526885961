import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Api from "@services/projects.service";

export const fetch_projects = createAsyncThunk(
    'projects/fetch_projects',
    async (_, thunkAPI) => {
        try {
            const response = await Api.get_projects();
            return response.data
        } catch {
            return []
        }
    }
)
export const projectsSlice = createSlice({
    name: 'home',
    initialState: {
        projects: [],
        is_fetching_projects: false
    },
    reducers: {
        setProjects: (state, action) => {
            state.projects = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetch_projects.fulfilled, (state, action) => {
            state.projects = action.payload
            state.is_fetching_projects = false
        })
        .addCase(fetch_projects.pending, (state) => {
            state.is_fetching_projects = true
        })
    },
})

export const { setProjects } = projectsSlice.actions

export default projectsSlice.reducer