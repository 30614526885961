import { configureStore } from '@reduxjs/toolkit'
import aboutSlice from './about.slice'
import contactSlice from './contact.slice'
import projectsSlice from './projects.slice'
import homeSlice from './home.slice'
import imagesSlice from './images.slice'
import configSlice from './config.slice'

export default configureStore({
    reducer: {
        about: aboutSlice,
        contact: contactSlice,
        projects: projectsSlice,
        home: homeSlice,
        images: imagesSlice,
        config: configSlice
    },
})