import { set_language } from "@store/config.slice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import "./Navbar/Navbar.css";

const LANGUAGES = {
    gr: "Greek",
    en: "English",
  };

function LanguageButton() {
    const { language } = useSelector((state) => state.config);
    const dispatch = useDispatch()

    const toggleLocalization = () => {
        const toggled_language = language == "en" ? "gr" : "en";
        dispatch(set_language(toggled_language))
        window.localStorage.setItem("language", toggled_language)
      };
    return (
        <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              transform: "translateY(-6.5px)",
              zIndex: 15,
            }}
            onClick={toggleLocalization}
          >
            {language == "en" ? (
              <div>{LANGUAGES.gr}</div>
            ) : (
              <div>{LANGUAGES.en}</div>
            )}
          </button>
    )
}


export default LanguageButton
