import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import "./Home.css";
import { useSelector, useDispatch } from "react-redux";
import { fetch_home } from "@store/home.slice";
import { fetch_home_images } from "@store/images.slice";
import { CSSTransition } from "react-transition-group";
import { useRef } from "react";
import { useState } from "react";
import { nav_options } from "@config/config";

const video_formats = ["mp4", "MP4", "webm", "WebM", "ogg", "Ogg", "m4v"];
const Home = () => {
  const { intro, is_fetching_home } = useSelector((state) => state.home);
  const { home_images, is_fetching_home_images } = useSelector(
    (state) => state.images
  );
  const dispatch = useDispatch();
  const nodeRef = useRef(null);
  const [inTransition, setInTransition] = useState(false);
  const [counter, setCounter] = useState(3);
  const [counter_interval, set_counter_interval] = useState(null);
  const { language } = useSelector((state) => state.config);

  useEffect(() => {
    if (counter === 0) {
      clearInterval(counter_interval);
    }
  }, [counter]);

  useEffect(() => {
    setInTransition(true);
    set_counter_interval(
      setInterval(() => {
        setCounter((c) => c - 1);
      }, 800)
    );

    if (!is_fetching_home) {
      fetch_h();
    }

    if (!is_fetching_home_images) {
      fetch_h_images();
    }
    return () => {
      setInTransition(false);
    };
  }, []);

  const fetch_h = async () => {
    if (intro === "") {
      await dispatch(fetch_home());
    }
  };

  const fetch_h_images = async () => {
    if (home_images.length === 0) {
      await dispatch(fetch_home_images());
    }
  };

  const noramlized_images = useMemo(() => {
    return home_images.map((i) => {
      return {
        src: i.acf.src,
        thumbnail: i.acf.thumbnail,
        isSelected: false,
      };
    });
  }, [home_images]);

  return (
    <>
      {counter > 0 && (
        <div className="counter_loader_container">
          <div className="counter_loader_wrapper">
            <div className="counter_loader">{counter}</div>
          </div>
        </div>
      )}
      <div className="home_container">
        <CSSTransition
          in={inTransition}
          nodeRef={nodeRef}
          timeout={1}
          classNames="navigation_bar"
          unmountOnExit
        >
          <div ref={nodeRef} className="navigation_bar">
            {
              nav_options.map((option) => 
                <div className="navigation_bar_item primary">
                  <Link to={option.value}>{option.label}</Link>
                </div>
              )
            }
          </div>
        </CSSTransition>
        {noramlized_images.length > 0 && (
          <div className="home_image_gallery_container">
            {noramlized_images.map((img) => {
              return (
                <>
                  {video_formats.includes(
                    img.src.split(".")[img.src.split(".").length - 1]
                  ) && (
                    <video
                      poster={img.thumbnail}
                      className="home_image_gallery_image"
                      muted="muted"
                      autoplay="autoplay"
                      loop
                    >
                      <source src={img.src} type="video/mp4" />
                      <source src={img.src} type="video/ogg" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                  {!video_formats.includes(
                    img.src.split(".")[img.src.split(".").length - 1]
                  ) && (
                    <img
                      className="home_image_gallery_image"
                      src={img.src}
                      alt=""
                    />
                  )}
                </>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default Home;
