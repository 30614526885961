import React from "react";
import { Route, Routes } from "react-router-dom";
import MainLayout from "./MainLayout";
import GreetLayout from "./GreetLayout";
import { Home, Projects, Geia, About, Contact, Images } from "..";
import { useDispatch } from "react-redux";
import "./App.css";
import { useEffect } from "react";
import AnimatedCursor from "react-animated-cursor";
import axios from "axios";
import Version from "@services/version.service";
import { useState } from "react";
import Config from "@services/config.service";
import { set_language } from "@store/config.slice";

function App() {
  const [fetchedVersion, setFetchedVersion] = useState(false);
  const dispatch = useDispatch()
  useEffect(async () => {
    const config = new Config()
    dispatch(set_language(config.get_language_from_local_storage()))
    const current_version = window.localStorage.getItem("version");
    setTimeout(() => {
      setFetchedVersion(true);
    }, 1000);
    const response = await get_version();
    const fetched_version = parseInt(response.data[0].acf.version);

    if (
      response &&
      response.data.length > 0 &&
      fetched_version > parseInt(current_version)
    ) {
      window.localStorage.clear();
    }
    window.localStorage.setItem("version", fetched_version);
    setFetchedVersion(true);
  }, []);

  const get_version = async () => {
    return await axios.get(Version.full_url());
  };

  if (!fetchedVersion) {
    return;
  }
  return (
    <>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route path="/geia" element={<Geia />} />
          <Route path="/anthologio" element={<Projects />} />
          <Route path="/koinoniko-minima" element={<About />} />
          <Route path="/epikoinonia" element={<Contact />} />
          <Route path="/eikones" element={<Images />} />
        </Route>
        <Route path="/" element={<GreetLayout />}>
          <Route index element={<Home />} />
        </Route>
      </Routes>
      <AnimatedCursor
        color="255,0,0"
        innerSize={8}
        outerSize={0}
        innerScale={1}
        outerScale={0}
        outerAlpha={0}
        outerStyle={{
          border: "3px solid red",
        }}
        trailingSpeed={0}
        innerStyle={{
          border: "0px solid rgba(242, 105, 56, 0.58)",
          backdropFilter: "invert(100%)",
          height: "20px",
          width: "20px",
          backgroundColor: "rgba(242, 105, 56, 0.58)",
        }}
      />
    </>
  );
}

export default App;
