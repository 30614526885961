import Client from "./client.service";
import { about } from "@config/config"
class About extends Client {
    static path = about

    static url = (path) => {
        return this.api_url + this.api_url_prefix + path
    }

    static get_about = async (page = 1, pagesize = 100, params = {}) => {
        const url = `${this.url(this.path.LIST)}?${this._query_string({ page, pagesize, ...params })}`
        return this._fetch(url)
    }
}

export default About