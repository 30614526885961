import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetch_projects } from "@store/projects.slice";
import "./Projects.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useWindowDimensions from "@hooks/screen_dimensions";
import Marquee from "react-fast-marquee";
import TypedText from "@components/TypedText";

const Projects = () => {
  const {projects, is_fetching_projects} = useSelector((state) => state.projects);
  const { language } = useSelector((state) => state.config);
  const dispatch = useDispatch();

  const { _, width } = useWindowDimensions();

  useEffect(() => {
    if (projects.length === 0 && !is_fetching_projects) {
      fetch();
    }
  }, []);

  const fetch = async () => {
    await dispatch(fetch_projects());
  };

  const ordered_projects = useMemo(() => {
    const copy_projects = [...projects]
    return copy_projects.sort((a, b) => {
      if(b.acf.order && a.acf.order) {
        return a.acf.order - b.acf.order
      }
      return 1
    })
  }, [projects])

  return (
    <div className="project_list_container generic_container">
      {width > 760 && ordered_projects.length >= 1 && <div className="slide_animated_border_wrapper">
        <div className="slide_animated_border slide_animated_border_1">
        </div>
        <div className="slide_animated_border slide_animated_border_2">
        </div>
      </div>}

      {width > 760 && ordered_projects.length >= 1 && (
        <Marquee
          speed="50"
          gradient="false"
          gradientWidth="0"
        >
          {ordered_projects.map((project) => {
            return (
              <div
                className="slide_item"
                oncopy="return false"
                onpaste="return false"
                oncut="return false"
                onclick="return false"
              >
                <div className="slide_item_text">
                  {language === "gr" ? project.acf["title"] :  project.acf["title-en"]}
                  <br />
                  {language === "gr" ? project.acf["subtitle"] :  project.acf["subtitle-en"]}
                </div>

                {project.acf.main_image && (
                  <div className="slide_item_image_container">
                    <img
                      onClick={() => {
                        if (project.acf.link.length > 1) {
                          window.open(
                            project.acf.link,
                            '_blank'
                          );
                        }
                      }}
                      className="slide_item_image"
                      src={project.acf.main_image}
                    />
                  </div>
                )}
                <p className="project_list_item_description">{language === "gr" ? project.acf["description"] :  project.acf["description-en"]}</p>
                {project.acf.link && project.acf.link.length > 1 &&
                  <div class="slide_item_link" ><a href={project.acf.link} target="_blank">{language === "en" ? "To webpage" : "Προς ιστοσελιδα"} {">"}{">"}</a></div>
                }
              </div>
            );
          })}
        </Marquee>
      )}
      {width <= 760 &&
        ordered_projects.length >= 1 &&
        ordered_projects.map((project) => {
          return (
            <div
              className="project_list_item_container"
              oncopy="return false"
              onpaste="return false"
              oncut="return false">
              <h4>{language === "gr" ? project.acf["title"] :  project.acf["title-en"]}</h4>
              <p className="project_list_item_subtitle">{language === "gr" ? project.acf["subtitle"] :  project.acf["subtitle-en"]}</p>
              <div className="slide_animated_border_wrapper_mobile">
                <div className="slide_animated_border slide_animated_border_1_mobile">
                </div>
                <div className="slide_animated_border slide_animated_border_2_mobile">
                </div>
              </div>
              <p className="project_list_item_description">{language === "gr" ? project.acf["description"] :  project.acf["description-en"]}</p>
              <div className="project_list_item_image_container">
                <img
                  className="project_list_item_image"
                  onClick={() => {
                    if (project.acf.link.length > 1) {
                      window.open(
                        project.acf.link,
                        '_blank'
                      );
                    }
                  }}
                  src={project.acf.main_image}
                  alt=""
                />
              </div>
              {project.acf.link && project.acf.link.length > 1 &&
                <div class="slide_item_link" ><a href={project.acf.link} target="_blank">{language === "en" ? "To webpage" : "Προς ιστοσελιδα"} {">"}{">"}</a></div>
              }
            </div>
          );
        })}
      {ordered_projects.length < 1 &&
        <div className="loader_wrapper">
          <TypedText text={[language === "en" ? "Please wait": "Παρακαλώ περιμένετε ...", 1000, ""]}  />
        </div>
      }
    </div>
  );
};

export default Projects;
