import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Api from "@services/home.service";

export const fetch_home = createAsyncThunk(
    'home/fetch_home',
    async (_, thunkAPI) => {
        try {
            const response = await Api.get_home();
            return response.data[0].acf
        } catch {
            return ""
        }
    }
)
export const homeSlice = createSlice({
    name: 'home',
    initialState: {
        intro: null,
        is_fetching_home: false
    },
    reducers: {
        setIntro: (state, action) => {
            state.intro = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetch_home.fulfilled, (state, action) => {
                state.intro = action.payload
                state.is_fetching_home = false
            })
            .addCase(fetch_home.pending, (state) => {
                state.is_fetching_home = true
            })
    },
})

export const { setIntro } = homeSlice.actions

export default homeSlice.reducer