import Client from "./client.service";
import { version } from "@config/config"

class Version extends Client {
    static path = version

    static url = (path) => {
        return this.api_url + this.api_url_prefix + path
    }

    static full_url = (page = 1, pagesize = 100, params = {}) => {
        return `${this.url(this.path.ENDPOINT)}?${this._query_string({ page, pagesize, ...params })}`
    }
}

export default Version