import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./Images.css";
import { Gallery } from "react-grid-gallery";
import { fetch_images } from "@store/images.slice";
import { useMemo } from "react";
import TypedText from "@components/TypedText";

const Images = () => {
  const { images, is_fetching_images } = useSelector((state) => state.images);
  const dispatch = useDispatch()
  const { language } = useSelector((state) => state.config);

  useEffect(() => {
    if (images.length === 0 && !is_fetching_images) {
      fetch();
    }
  }, []);

  const fetch = async () => {
    await dispatch(fetch_images());
  };

  const noramlized_images = useMemo(() => {
    return images.map((i) => {
      return {
        src: i.acf.src,
        isSelected: false,
      };
    });
  }, [images]);

  return (
    <div className="images_container generic_container">
      <Gallery rowHeight={300} margin={8} images={noramlized_images} />
      {noramlized_images.length < 1 && (
        <div className="loader_wrapper">
          <TypedText text={[language === "en" ? "Please wait": "Παρακαλώ περιμένετε ...", 1000, ""]} />
        </div>
      )}
    </div>
  );
};

export default Images;
