import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Api from "@services/contact.service";

export const fetch_contact = createAsyncThunk(
    'contact/fetch_contact',
    async (_, thunkAPI) => {
        try {
            const response = await Api.get_contact();
            return response.data[0].acf
        } catch {
            return ""
        }
    }
)
export const contactSlice = createSlice({
    name: 'about',
    initialState: {
        info: "",
        is_fetching_contact: false
    },
    reducers: {
        setInfo: (state, action) => {
            state.info = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetch_contact.fulfilled, (state, action) => {
                state.info = action.payload
                state.is_fetching_contact = false
            })
            .addCase(fetch_contact.pending, (state) => {
                state.is_fetching_contact = true
            })
    },
})

export const { setInfo } = contactSlice.actions

export default contactSlice.reducer