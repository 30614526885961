import { useSelector } from "react-redux";

const home = {
    LIST: "home"
}

const projects = {
    LIST: "projects",
    GET: "project"
}

const contact = {
    LIST: "contact"
}

const about = {
    LIST: "about"
}

const images = {
    LIST: "images"
}

const home_images = {
    LIST: "home-images"
}

const version = {
    ENDPOINT: "publish-version"
}

const OptionItem = (props) => {
    const { language } = useSelector((state) => state.config);
    return (props.label[language])
  }
  
const nav_options = [
    { value: "/geia", label:  <OptionItem label={{gr: "ΓΕΙΑ", en: "HI"}}/> },
    { value: "/anthologio", label: <OptionItem label={{gr: "ΑΝΘΟΛΟΓΙΟ", en: "ANTHOLOGY"}}/> },
    { value: "/eikones", label: <OptionItem label={{gr: "ΕΙΚΟΝΕΣ", en: "IMAGES"}}/> },
    { value: "/koinoniko-minima", label: <OptionItem label={{gr: "ΚΟΙΝΩΝΙΚΟ ΜΗΝΥΜΑ", en: "SOCIAL MESSAGE"}}/> },
    { value: "/epikoinonia", label: <OptionItem label={{gr: "ΕΠΙΚΟΙΝΩΝΙΑ", en: "CONTACT" }}/>},
];

export {
    projects,
    home,
    contact,
    about,
    images,
    home_images,
    version,
    nav_options
}