import Client from "./client.service";
import { images } from "@config/config"

class Images extends Client {
    static path = images

    static url = (path, id = "") => {
        return this.api_url + this.api_url_prefix + path + id
    }

    static get_images = async (page = 1, pagesize = 100, params = {}) => {
        const url = `${this.url(this.path.LIST)}?${this._query_string({ page, pagesize, ...params })}`
        return this._fetch(url)
    }
}

export default Images