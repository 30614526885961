import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { useNavigate, useLocation } from "react-router";
import { useEffect } from "react";
import { useState } from "react";
import { nav_options } from "@config/config";
import LanguageButton from "../LanguageButton";

const useAudio = (url) => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);
  const r_play = () => setPlaying(true);
  const r_pause = () => setPlaying(false);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  return [playing, toggle, r_pause, r_play];
};

function Navbar({ ...props}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [playing, toggle, r_pause, r_play] = useAudio("/audio/saranta_gid.mp3");

  useEffect(() => {
    r_play();
  }, []);

  return (
    <>
      <nav className="navbar_container">
       {props.show_menu &&  <div className="navbar_items">
          {
            nav_options.map(item => 
              <div key={item.value} className="navbar_item primary">
                <Link to={item.value}>{item.label}</Link>
              </div>)
          }
        </div>}
        <div className="navbar_aux">
          {props.show_menu && <div className="navbar_mobile_menu">
            <Dropdown
              options={nav_options}
              onChange={(e) => {
                navigate(e.value, { state: null });
              }}
              value={
                nav_options.filter((o) => o.value == location.pathname)[0] ||
                nav_options[0]
              }
            />
          </div>}
        </div>

        <div
          style={{
            margin: "18px 30px 0 0",
            zIndex: 50
          }}
        >
          <LanguageButton/>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
            }}
            onClick={toggle}
          >
            {playing ? (
              <img height="24" width="24" src="/images/pause.png" alt="" />
            ) : (
              <img height="24" width="24" src="/images/play.png" alt="" />
            )}
          </button>
        </div>
      </nav>
      <div className="helper_div"></div>
    </>
  );
}

export default Navbar;
