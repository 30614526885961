import React, { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-multi-carousel/lib/styles.css";
import "./Geia.css";
import { useSelector, useDispatch } from "react-redux";
import { fetch_home } from "@store/home.slice";
import { fetch_projects } from "@store/projects.slice";
import TypedText from "@components/TypedText";
import parse from "html-react-parser";
import { useMemo } from "react";

const Home = () => {
  const { intro, is_fetching_home } = useSelector((state) => state.home);
  const { language } = useSelector((state) => state.config);
  const { projects, is_fetching_projects } = useSelector(
    (state) => state.projects
  );
  const dispatch = useDispatch();

  const localized_intro = useMemo(() => {
    if (intro && Object.hasOwn(intro, 'intro') && Object.hasOwn(intro, 'intro-en')) {
      return language === "en" ? intro["intro-en"] : intro["intro"]
    }
    return ""
  }, [language, intro])  

  useEffect(() => {
    if (!is_fetching_home) {
      fetch_h();
    }

    if (!is_fetching_projects) {
      fetch_pr();
    }
  }, []);

  const fetch_h = async () => {
    if (intro === null) {
      await dispatch(fetch_home());
    }
  };

  const fetch_pr = async () => {
    if (projects.length === 0) {
      await dispatch(fetch_projects());
    }
  };

  return (
    <div
      className="generic_container"
      oncopy="return false"
      onpaste="return false"
      oncut="return false"
      onclick="return false"
    >
      {localized_intro === "" ? (
        <div className="loader_wrapper">
          <TypedText text={[language === "en" ? "Please wait": "Παρακαλώ περιμένετε ...", 1000, ""]} />
        </div>
      ) : (
        <div>
          {parse(localized_intro)}{" "}
        </div>
      )}
    </div>
  );
};

export default Home;
