import { set_language } from "@store/config.slice"; 

class Config {    
    get_language_from_local_storage = () => {
        const lang = window.localStorage.getItem("language")
        return lang ? lang : "gr"
    }
}

export default Config
