import React, { useRef } from "react";
import Navbar from "@components/Navbar/Navbar";
import "./MainLayout.css";
import { Outlet, useLocation } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";

const MainLayout = () => {
  const nodeRef = useRef(null);
  const location = useLocation()
  return (
    <div className="app_container">
      <Navbar show_menu={true}></Navbar>
      <div className="main_container">
        <SwitchTransition>
          <CSSTransition
            key={location.pathname}
            nodeRef={nodeRef}
            timeout={0}
            classNames="transitioned_container"
            unmountOnExit
          >
            {(state) => (
              <div ref={nodeRef} className="transitioned_container">
                <Outlet />
              </div>
            )}
            
          </CSSTransition>
        </SwitchTransition>
      </div>
    </div>
  );
};
export default MainLayout;
