import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Api from "@services/images.service";
import HomeApi from "@services/homeImages.service";

export const fetch_images = createAsyncThunk(
    'images/fetch_images',
    async (_, thunkAPI) => {
        try {
            const response = await Api.get_images();
            return response.data
        } catch {
            return []
        }
    }
)

export const fetch_home_images = createAsyncThunk(
    'images/fetch_home_images',
    async (_, thunkAPI) => {
        try {
            const response = await HomeApi.get_home_images();
            return response.data
        } catch {
            return []
        }
    }
)

export const imagesSlice = createSlice({
    name: 'home',
    initialState: {
        images: [],
        home_images: [],
        is_fetching_images: false,
        is_fetching_home_images: false
    },
    reducers: {
        setImages: (state, action) => {
            state.images = action.payload
        },
        setHomeImages: (state, action) => {
            state.home_images = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetch_images.fulfilled, (state, action) => {
                state.images = action.payload
                state.is_fetching_images = false
            })
            .addCase(fetch_home_images.fulfilled, (state, action) => {
                state.home_images = action.payload
                state.is_fetching_home_images = false
            })
            .addCase(fetch_images.pending, (state) => {
                state.is_fetching_images = true
            })
            .addCase(fetch_home_images.pending, (state) => {
                state.is_fetching_home_images = true
            })
    },
})

export const { setImages, setHomeImages } = imagesSlice.actions

export default imagesSlice.reducer