import { TypeAnimation } from "react-type-animation";
import { useMemo } from "react"

const TypedText = ({ ...props }) => {
  const text = useMemo(() => props.text, [props.text])
  return (
    <TypeAnimation
      sequence={text}
      wrapper="div"
      speed={80}
      cursor={true}
    />
  );
};

export default TypedText;
