import axios from "axios"

class Client {
    static api_url = process.env.REACT_APP_API_URL
    static api_url_prefix = process.env.REACT_APP_API_PREFIX
    
    static _query_string = (params) => {
        return Object.entries(params).map(e => e.join('=')).join('&');
    }

    static _put_to_local_storage = (key, payload) => {
        window.localStorage.setItem(key, JSON.stringify(payload))
    }

    static _get_from_local_storage = (key) => {
        try {
            const d = window.localStorage.getItem(key);
            return JSON.parse(d)
        } catch (error) {
            console.log(error)
        }
        return false
    }

    static _fetch = async (url) => {
        const data = this._get_from_local_storage(url)
        if(data && data !== false) {
            return data
        } else {
            const fetchedData = await axios.get(url)
            this._put_to_local_storage(url, fetchedData)
            return fetchedData
        }
    }
}

export default Client