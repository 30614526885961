import React from "react";
import "./GreetLayout.css";
import { Outlet } from "react-router-dom";
import LanguageButton from "@components/LanguageButton"

const GreetLayout = () => (
  <div className="greet_layout">
    <div  
      style={{
        position: "fixed",
        right: "20px",
        top: "20px",
        zIndex: 15
    }}>
    <LanguageButton/>
    </div>
    
    <Outlet />
  </div>
);
export default GreetLayout;
