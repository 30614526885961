import Client from "./client.service";
import { projects } from "@config/config"

class Projects extends Client {
    static path = projects

    static url = (path, id = "") => {
        return this.api_url + this.api_url_prefix + path + id
    }

    static get_projects = async (page = 1, pagesize = 100, params = {}) => {
        const url = `${this.url(this.path.LIST)}?${this._query_string({ page, pagesize, ...params })}`
        return this._fetch(url)
    }

    static get_project = async (page = 1, pagesize = 100, params = {}, id) => {
        const url = `${this.url(this.path.GET, id)}?${this._query_string({ page, pagesize, ...params })}`
        return this._fetch(url)
    }
}

export default Projects